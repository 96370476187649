<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        <router-link class="font-weight-medium text-decoration-none me-2" :to="{ name: 'Medicine-/-Equipment' }">
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" icon v-on="on">
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('back') }}</span>
          </v-tooltip>
        </router-link>
        <v-icon class="me-1">
          {{ dataDrug.drug_category_id == 1 ? icons.mdiPill : icons.mdiNeedle }}
        </v-icon>
        {{ dataDrug.drug_name }}

        <v-spacer></v-spacer>
        <v-btn color="primary" class="d-none d-md-block" @click="isAddDrugToStore = true">
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('import_warehouse') }}
        </v-btn>
        <v-btn color="primary" class="d-block d-md-none" fab outlined icon @click="isAddDrugToStore = true">
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-5" no-gutters>
        <v-col cols="12" md="6" lg="4">
          <p>
            <span class="font-weight-semibold"> {{ $t('code') }} : </span>{{ dataDrug.drug_id }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('category') }} : </span>{{ dataDrug.druggroup_name }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('direction') }} : </span>{{ dataDrug.drug_direction }}
          </p>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <p>
            <span class="font-weight-semibold">{{ $t('name') }} : </span>{{ dataDrug.drug_name }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('type') }} : </span>{{ dataDrug.drugtype_name }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('balance') }} : </span>
            <v-chip
              v-if="dataDrug.drug_total == null || dataDrug.drug_total == '0.00'"
              small
              color="error"
              class="v-chip-light-bg error--text"
            >
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
              {{ $t('empty') }}
            </v-chip>
            <span v-else>
              {{ dataDrug.drug_total }}
            </span>
          </p>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <p>
            <span class="font-weight-semibold">{{ $t('unit') }} : </span> {{ dataDrug.drug_unit }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('sale_price') }} : </span>{{ dataDrug.drug_price | formatPrice }}
          </p>
          <p>
            <span class="font-weight-semibold">{{ $t('other_details') }} : </span>{{ dataDrug.drug_detail }}
          </p>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="4">
          <v-select
            v-model="status"
            :items="statusList"
            outlined
            dense
            :label="$t('status')"
            :item-text="$i18n.locale"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>#</th>

              <th>
                {{ $t('lot_no') }}
              </th>
              <th class="text-left">
                {{ $t('lot') }}
              </th>
              <th class="text-left">
                {{ $t('dealer') }}
              </th>
              <th class="text-right">
                {{ $t('cost_price') }}
              </th>
              <th class="text-left">
                {{ $t('expire_date') }}
              </th>
              <th class="text-right">
                {{ $t('import') }}
              </th>
              <th class="text-right">
                {{ $t('transfer_out') }}
              </th>
              <th class="text-right">
                {{ $t('sale') }}
              </th>
              <th class="text-right">
                {{ $t('balance') }}
              </th>
              <th class="text-left">
                {{ $t('status') }}
              </th>
              <th class="text-left">
                {{ $t('option') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataDrugStockList.length == 0">
              <td colspan="12" class="text-center">{{ $t('no_data') }} !</td>
            </tr>
            <tr v-for="(item, index) in dataDrugStockList" v-else :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td style="min-width: 150px">
                {{ item.drugorder_id }}
              </td>
              <td style="min-width: 200px">
                <ConverseDate :date="item.druglot_date" />
                <span> ({{ item.druglot_name }})</span>
              </td>
              <td style="min-width: 150px">
                {{ item.drugfrom_name }}
              </td>
              <td v-if="showCost" class="text-right" style="min-width: 150px">
                {{ item.drugorder_cost | formatPrice }}
              </td>
              <td v-else class="text-right" style="min-width: 150px">xxx</td>
              <td style="min-width: 150px">
                <ConverseDate :date="item.drugorder_expdate" />
              </td>
              <td class="text-right" style="min-width: 150px">
                {{ item.drugorder_in | formatPrice }}
              </td>
              <td class="text-right" style="min-width: 150px">
                {{ item.drugorder_out | formatPrice }}
              </td>
              <td class="text-right" style="min-width: 150px">
                {{ item.drugorder_sold | formatPrice }}
              </td>
              <td class="text-right" style="min-width: 150px">
                {{ item.drugordert_total | formatPrice }}
              </td>
              <td style="min-width: 90px">
                <v-chip
                  v-if="item.drugorder_status_id == 1"
                  small
                  class="v-chip-light-bg success--text"
                  color="success"
                >
                  {{ $t('normal') }}
                </v-chip>
                <v-chip v-else color="error" small class="v-chip-light-bg error--text">
                  {{ $t('suspend') }}
                </v-chip>
              </td>
              <td style="min-width: 120px">
                <v-tooltip color="#212121" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      small
                      @click="
                        dataEdit = item
                        isEditDrugInStore = true
                      "
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
                <v-tooltip color="#212121" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      :disabled="item.drugorder_status_id != 1"
                      small
                      v-bind="attrs"
                      @click="
                        drugorder_id_pri = item.drugorder_id_pri
                        drugorder_status_id = item.drugorder_status_id
                        isUserConfirm = true
                      "
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('suspend') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <AddDrugToStore v-model="isAddDrugToStore" :drug-id-pri="drugIdPri" @onAdd="getDrugLotList" />
    <EditDrugInStore v-model="isEditDrugInStore" :data-edit="dataEdit" @onUpdate="getDrugLotList" />
    <UserConfirm v-model="isUserConfirm" @onSend="updateStatusDrug()" />
  </div>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import {
  mdiPrinter,
  mdiArrowLeftBold,
  mdiPill,
  mdiNeedle,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiPlus,
  mdiAlertOutline,
} from '@mdi/js'
import router from '@/router'
import drugStore from '@/api/DrugManagement/drugStore'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import statusInWareHouse from '@/@fake-db/data/statusInWareHouse.json'
import profile from '@/api/profile'
import AddDrugToStore from './AddDrugToStore.vue'
import EditDrugInStore from './EditDrugInStore.vue'
import store from '@/store'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    AddDrugToStore,
    ConverseDate,
    EditDrugInStore,
    UserConfirm,
  },
  setup() {
    const dataDrug = ref({})
    const loading = ref(true)
    const status = ref(1)
    const dataDrugStockList = ref([])
    const statusList = ref(statusInWareHouse.data)
    const showCost = ref(false)
    const isAddDrugToStore = ref(false)
    const drugIdPri = ref(router.currentRoute.params.id)
    const isEditDrugInStore = ref(false)
    const dataEdit = ref({})

    const drugorder_status_id = ref('')
    const drugorder_id_pri = ref('')

    const isUserConfirm = ref(false)

    const { drugGet, drugOrderList, drugUpdateStatusInStore } = drugStore
    const getDurg = () => {
      drugGet(router.currentRoute.params.id).then(res => {
        dataDrug.value = res
        loading.value = false
      })
    }
    onMounted(() => {
      getDrugLotList()
    })

    profile().then(res => {
      showCost.value = res.user_data_customers == '0'
    })

    const getDrugLotList = () => {
      drugOrderList({
        drug_id_pri: router.currentRoute.params.id,
        status_id: status.value,
      }).then(res => {
        dataDrugStockList.value = res
      })
      getDurg()
    }

    const updateStatusDrug = () => {
      drugUpdateStatusInStore({
        drugorder_id_pri: drugorder_id_pri.value,
        drugorder_status_id: drugorder_status_id.value == '1' ? '2' : '1',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getDrugLotList()
      })
    }

    watch(status, () => {
      getDrugLotList()
    })

    return {
      isUserConfirm,
      isEditDrugInStore,
      updateStatusDrug,
      drugorder_id_pri,
      mdiAlertOutline,
      drugorder_status_id,
      dataEdit,
      dataDrug,
      drugIdPri,
      icons: {
        mdiPrinter,
        mdiArrowLeftBold,
        mdiPill,
        mdiNeedle,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
        mdiAlertOutline,
      },
      loading,
      dataDrugStockList,
      statusList,
      status,
      showCost,
      isAddDrugToStore,
      getDrugLotList,
    }
  },
}
</script>
