<template>
  <div>
    <v-dialog
      v-model="isAddDrugToStore"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('import_warehouse') }}
        </v-card-title>
        <v-form
          ref="formAddDrugToStore"
          @submit.prevent="sendDrugToStore"
        >
          <v-card-text>
            <v-text-field
              v-model="drugorder_id"
              outlined
              :rules="[required]"
              :label="$t('lot_no')"
              dense
            ></v-text-field>
            <v-autocomplete
              v-model.trim="druglot_id"
              :items="drugLotList"
              :label="$t('search_lot')"
              class="mt-3"
              auto-select-first
              dense
              :rules="[required]"
              item-text="druglot_name"
              item-value="druglot_id"
              outlined
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t("no_data") }} !
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <ConverseDate :date="item.druglot_date" />
              </template>

              <template v-slot:item="{ item }">
                <ConverseDate :date="item.druglot_date" />
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="drugfrom_id"
              :items="drugFormList"
              :label="$t('dealer')"
              class="mt-3"
              auto-select-first
              dense
              item-value="drugfrom_id"
              outlined
            >
              <template v-slot:no-data>
                {{ $t("no_data") }} !
              </template>
              <template v-slot:selection="{ item }">
                {{ item.drugfrom_name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.drugfrom_name }}
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="drugorder_cost"
              outlined
              type="number"
              :rules="[required]"
              class="mt-3"
              :label="$t('cost_price')"
              dense
            ></v-text-field>
            <v-dialog
              ref="selectDate"
              v-model.trim="modal"
              :return-value.sync="drugorder_expdate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model.trim="drugorder_expdate"
                  outlined
                  :label="$t('expire_date')"
                  dense
                  :rules="[required]"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model.trim="drugorder_expdate"
                :locale="$i18n.locale"
                :min="minDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.selectDate.save(drugorder_expdate)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  {{ $t("cancel") }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model.trim="drugorder_in"
              outlined
              :rules="[required]"
              :label="$t('amount')"
              type="number"
              class="mt-3"
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="drugorder_in <= 0 || loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-drug-to-store', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import druglotList from '@/api/refs/druglotList'
import { required } from '@/@core/utils/validation'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import dealer from '@/api/DrugManagement/dealer'
import drugStore from '@/api/DrugManagement/drugStore'
import store from '@/store'

export default {
  components: {
    ConverseDate,
  },
  model: {
    prop: 'isAddDrugToStore',
    event: 'update:is-add-drug-to-store',
  },
  props: {
    isAddDrugToStore: Boolean,
    drugIdPri: String,
  },

  setup(props, { emit }) {
    const formAddDrugToStore = ref('')
    const modal = ref(false)

    const drugorder_id = ref('')
    const drug_id_pri = ref('')
    const druglot_id = ref('')
    const drugfrom_id = ref('')
    const drugorder_cost = ref('')
    const minDate = ref((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
    const drugorder_expdate = ref('')
    const drugorder_in = ref('')
    const drugLotList = ref([])
    const drugFormList = ref([])
    const loading = ref(false)

    const { druglotListGet } = druglotList
    const { dealerList } = dealer
    const { addDrugToStore } = drugStore

    druglotListGet({
      searhtext: '',
      druglot_status_id: 1,
    }).then(res => {
      drugLotList.value = res
      druglot_id.value = res[0].druglot_id
    })

    dealerList({
      searchtext: '',
      drugfrom_status_id: 1,
    }).then(res => {
      drugFormList.value = res
      drugfrom_id.value = res[res.length - 1].drugfrom_id
    })

    const sendDrugToStore = () => {
      const isFormValid = formAddDrugToStore.value.validate()
      if (!isFormValid) return
      loading.value = true
      addDrugToStore({
        drugorder_id: drugorder_id.value,
        drug_id_pri: props.drugIdPri,
        drugorder_cost: drugorder_cost.value,
        drugorder_expdate: drugorder_expdate.value,
        drugorder_in: drugorder_in.value,
        druglot_id: druglot_id.value,
        drugfrom_id: drugfrom_id.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'completed_successfully' : 'unsuccessful',
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-add-drug-to-store', false)
        drugorder_id.value = ''
        drugorder_cost.value = ''
        drugorder_expdate.value = ''
        drugorder_in.value = ''
        emit('onAdd')
      })
    }

    watch(() => props.isAddDrugToStore, () => {
      drugorder_id.value = ''
      drugorder_cost.value = ''
      drugorder_expdate.value = ''
      drugorder_in.value = ''
    })

    return {
      formAddDrugToStore,
      sendDrugToStore,
      drugorder_id,
      drug_id_pri,
      druglot_id,
      drugfrom_id,
      drugorder_cost,
      drugorder_expdate,
      drugorder_in,
      drugLotList,
      drugFormList,
      modal,
      loading,
      minDate,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
