import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const dealerList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drugfrom/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('Get dealder list err : ', err)

      return []
    })

  return response
}

const dealerAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugfrom',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add dealder err : ', err)

      return {}
    })

  return response
}

const dealerUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugfrom',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update dealder err : ', err)

      return {}
    })

  return response
}

const dealerUpdateStatus = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugfrom/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update dealder status err : ', err)

      return {}
    })

  return response
}

export default {
  dealerList, dealerAdd, dealerUpdate, dealerUpdateStatus,
}
