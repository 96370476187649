<template>
  <div>
    <v-dialog v-model="isEditDrugInStore" persistent max-width="500">
      <v-card>
        <v-card-title> {{ $t('edit') }}{{ $t('import_warehouse') }} </v-card-title>
        <v-form ref="formEditDrugInStore" @submit.prevent="updateDataDrugInStore">
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.drugorder_id"
              outlined
              :rules="[required]"
              :label="$t('lot_no')"
              dense
            ></v-text-field>
            <v-text-field
              v-model="druglot_id"
              :label="$t('search_lot')"
              class="mt-3"
              auto-select-first
              disabled
              dense
              :rules="[required]"
              outlined
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> {{ $t('no_data') }} ! </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <ConverseDate :date="item.druglot_date" />
              </template>

              <template v-slot:item="{ item }">
                <ConverseDate :date="item.druglot_date" />
              </template>
            </v-text-field>
            <v-autocomplete
              v-model="dataEditLocal.drugfrom_id"
              :items="drugFormList"
              :label="$t('dealer')"
              class="mt-3"
              auto-select-first
              dense
              item-value="drugfrom_id"
              outlined
            >
              <template v-slot:no-data> {{ $t('no_data') }} ! </template>
              <template v-slot:selection="{ item }">
                {{ item.drugfrom_name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.drugfrom_name }}
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="dataEditLocal.drugorder_cost"
              outlined
              type="number"
              :rules="[required]"
              class="mt-3"
              :label="$t('cost_price')"
              dense
            ></v-text-field>
            <v-dialog
              ref="selectDate"
              v-model.trim="modal"
              :return-value.sync="dataEditLocal.drugorder_expdate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataEditLocal.drugorder_expdate"
                  outlined
                  :label="$t('expire_date')"
                  dense
                  :rules="[required]"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="dataEditLocal.drugorder_expdate" :locale="$i18n.locale" :min="minDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.selectDate.save(dataEditLocal.drugorder_expdate)">
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn text color="primary" @click="modal = false">
                  {{ $t('cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:is-edit-drug-in-store', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import druglotList from '@/api/refs/druglotList'
import { required } from '@/@core/utils/validation'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import dealer from '@/api/DrugManagement/dealer'
import drugStore from '@/api/DrugManagement/drugStore'
import store from '@/store'

export default {
  components: {
    ConverseDate,
  },
  model: {
    prop: 'isEditDrugInStore',
    event: 'update:is-edit-drug-in-store',
  },
  props: {
    isEditDrugInStore: Boolean,
    drugIdPri: String,
    dataEdit: Object,
  },

  setup(props, { emit }) {
    const formEditDrugInStore = ref('')
    const modal = ref(false)

    const minDate = ref(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))

    const drugLotList = ref([])
    const drugFormList = ref([])

    const druglot_id = ref('')

    const loading = ref(false)

    const dataEditLocal = ref({})

    const { dealerList } = dealer
    const { updateDrugInStore } = drugStore

    dealerList({
      searchtext: '',
      drugfrom_status_id: 1,
    }).then(res => {
      drugFormList.value = res
    })

    const updateDataDrugInStore = () => {
      const isFormValid = formEditDrugInStore.value.validate()
      if (!isFormValid) return
      loading.value = true
      delete dataEditLocal.value.drugfrom_name
      delete dataEditLocal.value.druglot_date
      delete dataEditLocal.value.drugorder_in
      delete dataEditLocal.value.drugorder_out
      delete dataEditLocal.value.drugorder_sold
      delete dataEditLocal.value.drugorder_status_id
      delete dataEditLocal.value.drugordert_total
      updateDrugInStore(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-edit-drug-in-store', false)
        emit('onUpdate')
      })
    }

    watch(
      () => props.dataEdit,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          dataEditLocal.value = JSON.parse(JSON.stringify(newValue))
          druglot_id.value = dataEditLocal.value.druglot_name
        }
      },
    )

    return {
      formEditDrugInStore,
      updateDataDrugInStore,
      dataEditLocal,
      drugLotList,
      drugFormList,
      modal,
      loading,
      druglot_id,
      minDate,
      required,
    }
  },
}
</script>

<style lang="scss" scoped></style>
