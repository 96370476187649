import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const druglotListGet = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drug/lot/list',
      body,
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.error('get druglot list  err : ', err)

      return []
    })

  return response
}
export default { druglotListGet }
